import React from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import MoogsoftLogo from '../../img/moogsoft-symbol.svg';

export function App() {
  const styles = useStyles2(getStyles);
  return (
    <div className="m-a-2">
      <div className={styles.displayFlex}>
        <img src={MoogsoftLogo} height={32} width={32} className="m-r-1" />
        <h1>Moogsoft AIOps</h1>
      </div>
      <div className={styles.colorWeak}>Moogsoft AIOps App</div>
      <div className="m-t-1">The app enables rich reporting and dashboard features for Moogsoft OnPrem and Hosted.</div>
    </div>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  colorWeak: css`
    color: ${theme.colors.text.secondary};
  `,
  displayFlex: css`
    display: flex;
  `,
});
