import React, { useEffect, useState } from 'react';
import { useStyles2, InlineField, Icon, Input, Button, SecretInput } from '@grafana/ui';
import { PluginConfigPageProps, AppPluginMeta, PluginMeta, GrafanaTheme2 } from '@grafana/data';
import { getBackendSrv, getDataSourceSrv } from '@grafana/runtime';
import { css } from '@emotion/css';
import { lastValueFrom } from 'rxjs';

export type AppPluginSettings = {};

export interface AppConfigProps extends PluginConfigPageProps<AppPluginMeta<AppPluginSettings>> {}

export const AppConfig = ({ plugin }: AppConfigProps) => {
  const styles = useStyles2(getStyles);
  const { enabled, jsonData } = plugin.meta;
  const urlPattern = /^(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/;

  // Datasource state
  const [dsConfig, setDsConfig] = useState<Record<string, any>>({
    name: 'Moogsoft AIOps',
    type: 'moogsoft-aiops-datasource',
    isDefault: false,
    access: 'proxy',
    basicAuth: true,
    withCredentials: true,
    jsonData: { tlsSkipVerify: true },
    secureJsonFields: {},
    secureJsonData: {},
  });

  const [isPasswordConfigured, setIsPasswordConfigured] = useState<boolean>(false);

  // test response state
  const [testRes, setTestRes] = useState<Record<string, any>>();

  // URL input validation state
  const [isUrlValid, setIsUrlValid] = useState<boolean>(true);

  // get the datasource config on page mount
  useEffect(() => {
    getDsConfig();
  }, []);

  // validates the URL input on every input change
  useEffect(() => {
    if (dsConfig.url !== undefined) {
      setIsUrlValid(urlPattern.test(dsConfig?.url?.trim()));
    }
  }, [dsConfig.url]);

  // updates the datasource config
  const setConfig = (key, event) => {
    setDsConfig({
      ...dsConfig,
      [key]: event.target?.value,
    });
  };

  // removes the datasource config
  const removeDataSource = () => {
    return getBackendSrv().delete(`/api/datasources/${dsConfig.id}`);
  };

  const postUpdate = async (isPluginEnabled: boolean | undefined) => {
    // remove the datasource plugin if plugin is disabled and config is stored
    if (!isPluginEnabled && dsConfig.id) {
      return removeDataSource();
    }

    // trims the form input fields and
    // removes the empty & invalid ones from the payload
    const updatedDsConfig = { ...dsConfig };
    updatedDsConfig.url = updatedDsConfig.url?.trim();
    updatedDsConfig.basicAuthUser = updatedDsConfig.basicAuthUser?.trim();

    if (!isUrlValid) {
      delete updatedDsConfig.url;
    }
    if (updatedDsConfig.basicAuthUser === '') {
      delete updatedDsConfig.basicAuthUser;
    }
    if (updatedDsConfig.secureJsonData?.basicAuthPassword?.trim() === '') {
      delete updatedDsConfig.secureJsonData.basicAuthPassword;
    }

    // Update the existing datasource config if already present else add new one
    if (updatedDsConfig.id) {
      return getBackendSrv().put(`/api/datasources/${updatedDsConfig.id}`, updatedDsConfig);
    }
    return getBackendSrv().post(`/api/datasources`, updatedDsConfig);
  };

  // tests the current configured datasource
  const testDatasource = () => {
    getDataSourceSrv()
      .get(dsConfig.name)
      .then((instance) => {
        instance
          .testDatasource()
          .then((result) => {
            setTestRes({ status: result.status, message: result.message });
          })
          .catch((err) => {
            setTestRes({ status: 'error', message: 'HTTP Error ' + (err.statusText || err.message) });
          });
      });
  };

  // sets the datasource config based on the plugin state
  const getDsConfig = () => {
    setDsConfig({
      name: 'Moogsoft AIOps',
      type: 'moogsoft-aiops-datasource',
      access: 'proxy',
      basicAuth: true,
      withCredentials: true,
      jsonData: { tlsSkipVerify: true },
      secureJsonFields: {},
      secureJsonData: {},
    });

    if (!enabled) {
      return;
    }

    return getBackendSrv()
      .get('/api/datasources')
      .then((res) => {
        for (let ds of res) {
          if (ds.type === dsConfig.type && ds.name === dsConfig.name) {
            return getBackendSrv()
              .get(`/api/datasources/${ds?.id}`)
              .then((ds) => {
                // delete the url if empty
                // to avoid displaying error on the UI app mount
                if (ds.url === '') {
                  delete ds.url;
                }
                if (ds.secureJsonFields.basicAuthPassword) {
                  setIsPasswordConfigured(true);
                }
                setDsConfig(ds);
                testDatasource();
              });
          }
        }
        return;
      });
  };

  // Updates the plugin state and store the form field values
  const updatePluginAndReload = async (pluginId: string, data: Partial<PluginMeta>) => {
    try {
      await updatePlugin(pluginId, data);
      await postUpdate(data?.enabled);

      // Reloading the page as the changes made here wouldn't be propagated to the actual plugin otherwise.
      // This is not ideal, however unfortunately currently there is no supported way for updating the plugin state.
      window.location.reload();
    } catch (e) {
      console.error('Error while updating the plugin', e);
    }
  };

  return (
    <div>
      <h3 className="page-heading">Moogsoft AIOps Configuration</h3>
      Enter the URL and Graze API credentials of your Moogsoft AIOps instance.
      <br />
      <br />
      <div className={styles.marginBottom}>
        <InlineField label="URL" labelWidth={14} invalid={dsConfig.url?.trim() === '' || !isUrlValid}>
          <Input required width={42} value={dsConfig.url} onChange={(event) => setConfig('url', event)} minLength={0} />
        </InlineField>

        <InlineField label="User" labelWidth={14} invalid={dsConfig.basicAuthUser?.trim() === ''}>
          <Input
            required
            width={42}
            value={dsConfig.basicAuthUser}
            onChange={(event) => setConfig('basicAuthUser', event)}
            placeholder="user"
          />
        </InlineField>

        <InlineField label="Password" invalid={dsConfig.secureJsonData?.basicAuthPassword === ''} labelWidth={14}>
          <SecretInput
            width={42}
            value={dsConfig.secureJsonData?.basicAuthPassword}
            onChange={(event) =>
              setConfig('secureJsonData', {
                target: { value: { basicAuthPassword: (event.target as HTMLInputElement).value } },
              })
            }
            placeholder="password"
            isConfigured={isPasswordConfigured}
            onReset={() => setIsPasswordConfigured(false)}
          />
        </InlineField>
      </div>
      {testRes && (
        <div className="section m-b-0">
          <div className={`alert-${testRes.status} alert`}>
            <div className="alert-icon">
              <Icon name={testRes.status === 'error' ? 'exclamation-triangle' : 'check'} size="xl" type="solid" />
            </div>
            <div className="alert-body">
              <div className="alert-title">{testRes.message}</div>
            </div>
          </div>
        </div>
      )}
      <div className="m-b-0">
        <div className={styles.marginTop}>
          <Button
            className={enabled ? styles.marginRight : ''}
            variant="primary"
            onClick={() =>
              updatePluginAndReload(plugin.meta.id, {
                enabled: true,
                pinned: true,
                jsonData,
              })
            }
          >
            {enabled ? 'Update' : 'Enable'}
          </Button>

          {enabled && (
            <>
              <Button
                variant="destructive"
                onClick={() =>
                  updatePluginAndReload(plugin.meta.id, {
                    enabled: false,
                    pinned: false,
                    jsonData,
                  })
                }
              >
                Disable
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  marginTop: css`
    margin-top: ${theme.spacing(10)};
  `,
  marginBottom: css`
    margin-bottom: ${theme.spacing(5)};
  `,
  marginRight: css`
    margin-right: ${theme.spacing(1)};
  `,
});

export const updatePlugin = async (pluginId: string, data: Partial<PluginMeta>) => {
  const response = getBackendSrv().fetch({
    url: `/api/plugins/${pluginId}/settings`,
    method: 'POST',
    data,
  });
  return lastValueFrom(response);
};
